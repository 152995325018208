@font-face {
    font-family: 'Daydream';
    src: url('Daydream.eot');
    src: url('Daydream.eot?#iefix') format('embedded-opentype'),
        url('Daydream.woff') format('woff'),
        url('Daydream.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

