@import url("../fonts/stylesheet.css");
@font-face {
  font-family: "daydream";

  src: url("../fonts/Daydream.eot");

  src: url("../fonts/Daydream.eot") format("eot"),
    url("../fonts/Daydream.woff") format("woff"),
    url("../fonts/Daydream.ttf") format("truetype");

  font-weight: 300;

  font-style: normal;
}
/***

====================================================================
	Reset
====================================================================

***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
button:focus {
  outline: none;
}
::-webkit-scrollbar {
  width: 6px;
  z-index: -1;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-thumb {
  background: #544f44;
  border-radius: 5px;
  transition: 0.2s linear;
}
::-webkit-scrollbar-thumb:hover {
  background: #989d53;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/***

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.7em;
  font-weight: 400;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
}
/* Typography */
h1 {
  font-size: 60px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 34px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  position: relative;
  line-height: 1.8em;
}
.auto__container {
  position: relative;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}
.big__container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
.page__wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}
ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.centered {
  text-align: center;
}
/* banner__Section
========================================================= */
.banner {
  background: url(../images/home.png);

  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
}
.header__inner .left {
  display: flex;
  align-items: center;
}
.nav {
  margin-left: 20px;
}
.social__nav {
  display: flex;
  align-items: center;
}
.nav__link {
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #544f44;
  transition: 0.2s linear;
}
.nav__link:hover {
  color: #514428;
}
.nav__link:last-child {
  margin-right: 0;
}
.social__nav-link {
  margin-right: 15px;
}
.social__nav-link:last-child {
  margin-right: 0;
}
/* soon
====================================== */
.soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eaeeb9;
  border-radius: 8px;
  padding: 10px;
  width: 450px;
  height: 450px;
}
.soon__inner {
  border: 1px solid #a5a87d;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.soon__inner .title {
  font-family: "daydream";
  font-style: normal;
  font-weight: normal;
  font-size: 94px;
  line-height: 103px;
  color: #544f44;
  position: relative;
}
.soon__inner .slogan {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1em;
  color: #544f44;
  margin-bottom: 17px;
}
.soon__inner .rotate__title {
  font-family: "daydream";
  font-style: normal;
  font-weight: normal;
  font-size: 94px;
  line-height: 103px;
  color: #a5a87d;
  transform: rotate(-9.41deg);
}
.soon__inner .title .icon__outer {
  position: absolute;
  top: -70%;
  left: 20%;
}
/* about
=================================================== */
.about {
  margin-top: 50px;
}
.about__inner {
  background: #cfd39b;
  border-radius: 8px;
  padding: 10px;
}
.about__inner-box {
  border: 1px solid #ecf0c5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px;
}
.about__inner-box .rotate__title {
  font-family: "daydream";
  font-style: normal;
  font-weight: normal;
  font-size: 120px;
  line-height: 132px;
  color: #a5a87d;
  transform: rotate(-8.84deg);
  position: relative;
  margin-top: 60px;
}
.about__inner-box .text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #544f44;
  width: 70%;
  margin-top: 17px;
}
.about__inner-box .rotate__title .icon__outer {
  position: absolute;
  top: -50%;
  left: 20%;
}
/* form
========================================================== */
.form {
  margin-top: 30px;
}
.form__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.form__inner .title {
  margin-bottom: 18px;
}
.form__inner .rotate__title {
  font-family: "daydream";
  font-style: normal;
  font-weight: normal;
  font-size: 120px;
  line-height: 132px;
  text-align: center;
  color: #ecf0c5;
  transform: rotate(-8.84deg);
  position: relative;
}
.form__inner .title .slogan {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #544f44;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.form__row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.form__row {
  flex-wrap: wrap;
}
.form__row .input__outer {
  width: 250px;
  margin-right: 10px;
}
.form__row .input__outer input {
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #ecf0c5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(84, 79, 68, 0.4);
}
.input__outer-sm {
  width: 160px;
  margin: 20px 0;
}
.input__outer-sm input {
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #ecf0c5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(84, 79, 68, 0.4);
}
.btn__register {
  background: #cfd39b;
  border: 1px solid #ecf0c5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 20px;
  width: 160px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #544f44;
  text-align: center;
}
/* botBanner
===================================================== */
.botBanner {
  margin: 30px 0;
}
.botBanner__inner {
  background: url(../images/3614718\ 1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  border-radius: 8px;
  padding: 10px;
  height: 430px;
}
.boBanner__inner-box {
  border: 0px solid #ecf0c5;
  box-sizing: border-box;
  border-radius: 8px;
}
.boBanner__inner-box .title {
  font-family: "daydream";
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 62px;
  text-align: center;
  color: #adb45d;
  margin-top: 30px;
}
/* footer
================================================ */
.footer {
  background: #f0f0f0;
}
.footer__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px 0;
}
.social__nav-footer {
  display: flex;
  align-items: center;
}
.middle__abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.middle__abs .terms {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #989d53;
}
.middle__abs .copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #544f44;
}
/* radio
buton====================================== */
.radio__outer {
  position: relative;
  width: 19px;
  height: 19px;
  border: 1px solid #a5a87d;
  border-radius: 2px;
  margin-right: 10px;
}
.radio__outer .radio__label {
  position: absolute;
  width: 11px;
  height: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s linear;
}
input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + label {
  background: #a5a87d;
  border: 1px solid #a5a87d;
  border-radius: 2px;
}
.check__box-row {
  display: flex;
  align-items: center;
  margin: 5px 15px;
}
/* hamburger */
.ham {
  position: relative;
  display: none;
}
@media (max-width: 860px) {
  .ham {
    display: block;
    margin-right: 15px;
  }
  .social__nav {
    display: none;
  }
  .nav {
    display: none;
  }
}
.ham.open .ham__btn {
  transform: translateX(-50px);
  background: transparent;
}
.ham.open .ham__btn::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.ham.open .ham__btn::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
.ham__btn {
  width: 35px;
  height: 5px;
  border-radius: 2px;
  background: #989d53;
  transition: 0.3s linear;
}
.ham__btn::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 5px;
  bottom: 5px;
  border-radius: 2px;
  background: #514428;
  transition: 0.3s linear;
}
.ham__btn::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 5px;
  top: 5px;

  border-radius: 2px;
  background: #989d53;
  transition: 0.3s linear;
}
.ham__btn::before {
  transform: translateY(-5px);
}
.ham__btn::after {
  transform: translateY(5px);
}
.js__menu {
  position: fixed;
  top: 95px;
  bottom: 0;
  right: 0;
  z-index: 5;
  width: 0;
  transition: 0.5s linear;
  background: rgba(152, 157, 83, 0.7);
  opacity: 0;
}
.js__menu-bg {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 60%;
  transition: 1s linear;
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
}
.js__menu-inner {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #000;
  transition: 0.2s linear;
}
.js__menu-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #000;
  transition: 0.2s linear;
}
.js__menu-inner:hover {
  background: #989d53;
  color: #fff;
}
.js__menu-inner a {
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.25px;
  color: #514428;
}
.register__modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  /* overflow: auto; */
  /* Enable scroll if needed */

  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2); /* Black w/ opacity */
}
.register__modal-inner {
  background-color: #fefefe;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 70%;
  /* height: 70%; */
  animation-name: animateleftafisha;
  animation-duration: 0.4s;
  padding: 10px;
  position: relative;
}
.register__modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__modal-header .logo__outer img {
  width: 92px;
}
.register__modal-header .title {
  font-size: 58px;
  font-family: "daydream";
  line-height: 70px;
}
.register__modal-inner .text__area {
  color: #707070;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.register__modal-inner .text__area2 {
  color: #707070;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 24px;
}

.modal__container {
  margin: 30px auto;
  max-width: 1000px;
}
.modal__btn-row {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__btn-row a {
  margin-right: 20px;
}
.modal__btn-row a:last-child {
  margin-right: 0;
}
.modal__btn-brown {
  background: #cfd39b;
  border: 1px solid #ecf0c5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #544f44;
  text-align: center;
  min-width: 200px;
  max-width: 300px;
  margin: 0px 0px 0px 10px;
}
.modal__btn-grey {
  background: #fafaf8 0% 0% no-repeat padding-box;
  border: 1px solid #ecf0c5;
  border-radius: 4px;
  opacity: 1;
  padding: 14px 20px;
  min-width: 140px;
  max-width: 200px;
  margin: 0px 0px 0px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #544f44;
  text-align: center;
}
.hide {
  display: none;
}
@keyframes animateleftafisha {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}
.show {
  width: 100%;
  flex-direction: column;
  opacity: 1;
}
@media (max-width: 850px) {
  .form__row {
    flex-wrap: wrap;
  }
  .check__box {
    width: 45%;
    margin-right: 15px;
  }
  .form__row .input__outer {
    width: 45%;
    margin: 5px 15px 5px 0;
  }
  .input__outer-sm {
    width: 45%;
    margin: 5px 15px 5px 0;
  }
}
@media (max-width: 637px) {
  .register__modal-inner {
    overflow-y: scroll;
    height: 85%;
  }
}
@media (max-width: 600px) {
  .js__menu-bg {
    left: 20%;
  }
  .header__inner {
    padding: 13px 0;
  }
  .middle__abs {
    position: unset;
    transform: translate(0%, 0%);
  }
  .footer__inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal__btn-row {
    flex-direction: column;
  }
  .modal__btn-row a {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .register__modal-inner {
    width: unset;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    padding: 30px 10px;
  }
}
@media (max-width: 520px) {
  .auto__container {
    padding: 0;
  }
  .soon {
    width: 90%;
    height: 350px;
  }
  .soon__inner .rotate__title {
    font-size: 35px;
    line-height: 40px;
  }
  .about__inner-box {
    padding: 15px;
  }
  .about__inner-box .text {
    width: 100%;
    text-align: justify;
  }
  .about__inner-box .rotate__title {
    font-size: 50px;
    line-height: 55px;
    margin-top: 15px;
  }
  .about__inner-box .rotate__title .icon__outer {
    width: 50%;
  }
  .form__row {
    width: 90%;
    padding: 0 15px;
  }
  .modal__container {
    margin: 0 auto;
  }
  .check__box {
    width: 95%;
    margin-right: 15px;
  }
  .form__row .input__outer {
    width: 95%;
    margin: 5px 15px 5px 0;
  }
  .input__outer-sm {
    width: 95%;
    margin: 5px 15px 5px 0;
  }
}
@media (max-width: 420px) {
  .form__row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .form__row .input__outer {
    width: 100%;
    margin-bottom: 10px;
  }
  .form__row .input__outer input {
    width: 100%;
  }
  .input__outer-sm {
    width: 100%;
  }
  .btn__register {
    width: 100%;
  }
  .form__inner .rotate__title {
    font-size: 60px;
    line-height: 65px;
    transform: rotate(0);
  }
  .form__inner .title .slogan {
    position: unset;
    transform: translate(0, 0);
    text-align: center;
  }
  .logo__outer {
    align-items: flex-start;
  }
  .register__modal-header .title {
    font-size: 50px;
  }
  .register__modal-header .logo__outer {
    width: 50px;
  }
  .modal__btn-brown {
    width: 90%;
  }
  .modal__btn-grey {
    width: 70%;
  }
}
@media (max-height: 660px) {
  .register__modal-inner {
    overflow-y: scroll;
    height: 100%;
  }
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
